/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@font-face {
  font-family: 'Pangram-Black';
  src: local('Pangram-Black'),
    url('assets/fonts/pangram-black.otf') format('truetype');
}
@font-face {
  font-family: 'Pangram-Bold';
  src: local('Pangram-Bold'),
    url('assets/fonts/pangram-bold.otf') format('truetype');
}
@font-face {
  font-family: 'Pangram-ExtraBold';
  src: local('Pangram-ExtraBold'),
    url('assets/fonts/pangram-extra-bold.otf') format('truetype');
}
@font-face {
  font-family: 'Pangram-ExtraLight';
  src: local('Pangram-ExtraLight'),
    url('assets/fonts/pangram-extra-light.otf') format('truetype');
}
@font-face {
  font-family: 'Pangram-Light';
  src: local('Pangram-Light'),
    url('assets/fonts/pangram-light.otf') format('truetype');
}
@font-face {
  font-family: 'Pangram-Medium';
  src: local('Pangram-Medium'),
    url('assets/fonts/pangram-medium.otf') format('truetype');
}
@font-face {
  font-family: 'Pangram-Regular';
  src: local('Pangram-Regular'),
    url('assets/fonts/pangram-regular.otf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --direction-page: ltr;
  --flex-row: row;
  --flex-item: row;
  --text-item: left;
}
.dynamic-align {
  text-align: var(--text-item);
}

.dynamic-flex {
  flex-direction: var(--flex-row);
}

@layer base {
  html {
    @apply text-custom-gray-1;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='radio'] {
    @apply border-custom-gray-5 w-[14px] h-[14px] focus:ring-2 focus:ring-custom-turquoise checked:bg-none checked:ring-2 ring-2 ring-offset-2 ring-custom-gray-5 checked:ring-custom-turquoise;
    color: #3f9aa1;
  }

  input[type='range'] {
    @apply h-1 bg-custom-gray-green rounded-lg appearance-none cursor-pointer accent-custom-turquoise;
  }

  input[type='range' i]::-webkit-slider-thumb {
    @apply w-5 h-5 bg-custom-turquoise  h-9 w-9 sm:h-5 sm:w-5 rounded-full;
    -webkit-appearance: none;
  }

  input[type='range']::-moz-range-thumb,
  input[type='range']::-ms-thumb,
  input[type='range']::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
  }
}

@layer utilities {
  .custom-shadow-1 {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .custom-shadow-2 {
    box-shadow: 0 4px 6px 0 rgba(143, 31, 98, 0.12);
  }

  .custom-shadow-3 {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  }

  .custom-shadow-4 {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  }

  .custom-shadow-5 {
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
  }

  .button-shadow-1 {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .filter-drop-shadow-1 {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
  }

  .filter-drop-shadow-2 {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12));
  }

  .popup-shadow {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
  }
}

@layer components {
  html {
    @apply text-custom-black;
  }
}

@layer utilities {
  .custom-shadow-1 {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

@layer components {
  /********************************
            GENERAL
            ********************************/
  .skeleton {
    @apply block animate-pulse w-full h-full bg-slate-200 rounded-[inherit];
  }

  .h-full-screen {
    @apply h-[calc(var(--vh,_1vh)_*_100)] sm:h-screen;
  }

  .page {
    @apply flex flex-col flex-1 w-full h-full py-8 px-6 sm:py-10 sm:px-8 lg:py-14 lg:px-12 overflow-y-auto;
  }

  .community-page {
    @apply flex justify-center flex-1 h-full w-full px-5 pt-5 sm:pt-26;
  }

  .container-1 {
    @apply bg-white rounded-xl p-5 flex flex-col w-full;
  }

  /********************************
            TYPOGRAPHY
            ********************************/
  .h1 {
    @apply text-[4rem] font-light leading-[6.25rem] tracking-normal;
  }

  .h2 {
    @apply text-5xl font-medium leading-[4.5rem] tracking-[-2px];
  }

  .h2-pangram {
    @apply text-5xl font-light leading-[3.625rem];
  }

  .h3 {
    @apply text-4xl font-normal leading-[3.375rem];
  }

  .h4 {
    @apply text-2xl font-medium leading-9;
  }

  .h5 {
    @apply text-2xl font-normal leading-9;
  }

  .body1 {
    @apply text-xl font-normal leading-[1.875rem];
  }

  .body1-pangram {
    @apply text-base font-normal leading-[1.2rem];
  }

  .body2 {
    @apply text-base font-normal leading-[1.6rem];
  }

  .body3 {
    @apply text-sm font-normal leading-[1.313rem];
  }

  .caption {
    @apply text-xs font-bold leading-[1.125rem];
  }

  .title {
    @apply font-poppins text-3xl sm:text-4xl font-bold capitalize;
  }

  .body {
    @apply text-lg font-source-sans;
  }

  /********************************
            BUTTON
            ********************************/
  .btn-base {
    @apply ease-linear font-poppins transition-all active:scale-95 custom-shadow-1 py-2 px-4 disabled:bg-custom-gray-5 disabled:text-custom-gray-4 disabled:pointer-events-none disabled:shadow-none cursor-pointer flex items-center justify-center capitalize rounded font-medium;
  }

  .btn-default-contained {
    @apply text-black bg-white custom-shadow-1 hover:bg-gray-100 active:bg-gray-100;
  }

  .btn-success-contained {
    @apply text-white bg-custom-green-1 hover:bg-green-500 active:bg-green-500;
  }

  .btn-yellow-contained {
    @apply text-custom-black bg-custom-yellow-1 hover:bg-yellow-400 active:bg-yellow-400;
  }

  .btn-inverted-contained {
    @apply text-white bg-custom-black hover:bg-slate-800 active:bg-slate-800;
  }

  .btn-pink-contained {
    @apply text-white bg-custom-pink button-shadow-1;
  }

  .btn-pink-contained-2 {
    @apply text-white bg-custom-pink button-shadow-1;
  }

  .btn-telegram-contained {
    @apply text-white bg-gradient-to-b from-custom-telegram-1 to-custom-telegram-2;
  }

  .btn-black-contained {
    @apply text-white custom-shadow-1 bg-black hover:bg-black active:bg-black;
  }

  .btn-red-contained {
    @apply text-white custom-shadow-1 bg-custom-red-2 hover:bg-custom-red-2 active:bg-custom-red-2;
  }

  .btn-white-outlined {
    @apply border border-white text-white bg-custom-black hover:bg-slate-800 active:bg-slate-800;
  }

  .btn-size-sm {
    @apply font-medium text-sm;
  }

  .btn-size-md {
    @apply font-medium text-base;
  }

  /* new button styles */
  .btn {
    @apply cursor-pointer transition-all ease-linear flex items-center justify-center gap-3 px-6 py-2 sm:py-3 body2 rounded capitalize leading-6 tracking-[-0.02em] disabled:cursor-auto;
  }

  .btn-text {
    @apply bg-transparent shadow-none hover:bg-custom-gray-6 active:bg-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-outlined {
    @apply border border-custom-gray-5 hover:bg-custom-gray-6 active:bg-custom-gray-4 disabled:border-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-yellow {
    @apply text-custom-gray-1 bg-custom-yellow hover:bg-custom-yellow-hover active:bg-custom-yellow-click disabled:bg-custom-gray-4 disabled:text-white;
  }

  .btn-white {
    @apply text-custom-gray-1 bg-white hover:bg-white active:bg-white disabled:bg-custom-gray-4 disabled:text-white;
  }

  .btn-purple {
    @apply text-white bg-custom-purple hover:bg-custom-purple-hover active:bg-custom-purple-click disabled:bg-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-dark-blue {
    @apply text-white bg-custom-dark-blue hover:bg-custom-navy-blue active:bg-custom-navy-blue disabled:bg-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-turquoise {
    @apply text-white bg-custom-turquoise-2 hover:bg-custom-turquoise active:bg-custom-turquoise-dark disabled:bg-custom-gray-4 disabled:text-custom-gray-4;
  }

  .btn-pink {
    @apply text-white bg-custom-pink hover:bg-custom-pink-hover active:bg-custom-pink-click disabled:bg-custom-gray-4;
  }

  .btn-outlined-red {
    @apply text-custom-error-red border-custom-error-red bg-custom-error-red bg-opacity-10 hover:bg-custom-error-red hover:bg-opacity-5;
  }

  .btn-telegram {
    @apply text-white bg-gradient-to-b from-custom-telegram-1 to-custom-telegram-2;
  }

  .btn-sm {
    @apply px-6 py-1;
  }

  .btn-md {
    @apply px-6 py-2;
  }

  .btn-lg {
    @apply px-6 py-3;
  }

  .btn > svg {
    @apply fill-current;
  }

  /********************************
            FORM ELEMENTS
            ********************************/
  .input {
    @apply border border-custom-gray-5 outline-none focus:ring-0 focus:border-custom-gray-4 py-3 px-4 body2 rounded leading-6 tracking-[-0.02em] resize-none disabled:bg-custom-gray-4;
  }

  /********************************
            DROPDOWN
            ********************************/
  .dropdown-item-base {
    @apply px-4 py-2 text-sm font-poppins h-12 flex items-center gap-1.5 capitalize cursor-pointer;
  }

  .dropdown-item-disabled {
    @apply bg-custom-gray-6 text-custom-gray-4 cursor-auto;
  }

  .dropdown-item-default {
    @apply bg-white text-custom-black hover:bg-gray-100 active:bg-gray-100;
  }

  .dropdown-item-inverted {
    @apply bg-custom-black text-white hover:bg-slate-800 active:bg-slate-800;
  }

  .dropdown-item-yellow {
    @apply bg-custom-yellow-1 text-custom-black hover:bg-yellow-300 active:bg-yellow-300;
  }

  /* new styles */
  .menu-item-base {
    @apply p-2.5 bg-white body2 text-custom-gray-1 capitalize cursor-pointer w-full flex items-center gap-3 hover:bg-custom-gray-6 active:bg-custom-gray-4;
  }

  /********************************
          CALENDAR
          ********************************/
  .react-calendar {
    @apply sm:w-[600px] w-[257px];
  }

  .react-calendar__viewContainer {
    @apply flex justify-between w-full;
  }

  .react-calendar__navigation__arrow {
    @apply object-none;
  }

  .react-calendar__navigation__label {
    @apply text-3xl;
  }

  .react-calendar__month-view__days {
  }

  .react-calendar__month-view__weekdays {
    @apply text-xs font-poppins text-custom-gray-3 pt-5 pb-3 text-center;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button:disabled {
    @apply hidden;
  }

  .react-calendar__tile.react-calendar__year-view__months__month:disabled {
    @apply text-custom-gray-4;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    @apply hidden;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    @apply hidden;
  }

  .react-calendar__navigation {
    @apply flex;
  }

  .react-calendar__navigation__label {
    @apply flex justify-around text-base text-custom-black;
  }

  .react-calendar__month-view {
    @apply w-[250px];
  }

  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    @apply opacity-0;
  }

  .react-calendar__navigation__label__divider {
    @apply hidden;
  }

  .react-calendar__tile {
    @apply w-[35px] h-[35px];
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    @apply text-sm font-poppins;
  }

  .react-calendar__month-view__weekdays__weekday {
    @apply text-xs no-underline;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    @apply no-underline decoration-0;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    @apply bg-custom-blue-1;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
    @apply bg-custom-blue-1 text-white rounded-l-full;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    @apply bg-custom-blue-1 text-white rounded-l-full;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
    @apply bg-custom-turquoise text-white rounded-r-full;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    @apply bg-custom-blue-1 text-white rounded-r-full;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    @apply bg-custom-blue-1/[0.2];
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
    @apply bg-custom-blue-1/[0.2];
  }

  .react-calendar__year-view__months {
    @apply flex flex-col px-[90px];
  }

  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    @apply border rounded-full border-custom-gray-4;
  }

  /* launch date calendar */
  .launch-calendar {
    @apply max-sm:w-full;
  }

  .launch-calendar .react-calendar__month-view {
    @apply max-sm:w-full;
  }

  .launch-calendar .react-calendar__month-view__weekdays {
    @apply pt-[27px] pb-[15px];
  }

  .launch-calendar .react-calendar__month-view__weekdays__weekday {
    @apply caption text-custom-gray-3;
  }

  .launch-calendar .react-calendar__navigation__label__labelText {
    @apply body2 text-custom-gray-1;
  }

  .launch-calendar button.react-calendar__tile:disabled {
    @apply text-custom-gray-4;
  }

  .launch-calendar .react-calendar__tile.react-calendar__month-view__days__day {
    @apply body3;
  }

  /*.launch-calendar,*/
  /*.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {*/
  /*  @apply bg-custom-turquoise;*/
  /*}*/

  .launch-calendar
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    @apply bg-custom-turquoise;
  }

  .launch-calendar,
  .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    color: #202020 !important;
    @apply text-custom-gray-1;
  }

  /*  end of calendar*/
  [type='checkbox'] {
    color: black;
  }

  input {
    -webkit-appearance: none;
    box-shadow: none !important;
  }

  :-webkit-autofill {
    color: #fff !important;
  }

  /* date picker */
  .custom-date-picker {
    @apply w-full;
  }

  .custom-date-picker .react-date-picker__wrapper {
    @apply w-full border border-custom-gray-5 p-4;
  }

  .custom-date-picker .react-calendar__navigation {
    @apply items-center;
  }

  .custom-date-picker .react-calendar__month-view {
    @apply w-full;
  }

  .dots-menu {
    @apply w-6 h-6 rounded-full hover:bg-custom-gray-6 active:bg-custom-gray-5;
  }

  .dots-menu-vertical {
    @apply dots-menu rotate-90;
  }
}

/* Customize the progress bar color */
progress::-webkit-progress-bar {
  height: 12px;
  border-radius: 15px;
  background-color: rgba(160, 121, 237, 0.3);
}

/* Customize the progress value color */
progress::-webkit-progress-value {
  border-radius: 15px;
  background-color: rgba(160, 121, 237, 1);
}
