.community-wizard .slick-list,
.community-wizard .slick-track,
.community-wizard .slick-slide > div {
  height: 100%;
}
.community-wizard .slick-list,
.community-wizard .slick-track,
.community-wizard .slick-slide {
  height: 100%;
  display: flex;
}

.community-wizard .slick-slider {
  flex: 1;
  height: calc(100% - 20rem);
  width: 100vw;
}

@media only screen and (min-width: 768px) {
  .community-wizard .slick-slider {
    width: calc(100vw - 300px);
  }
}
