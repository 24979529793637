.cover-photo-wizard .slick-list,
.cover-photo-wizard .slick-track,
.cover-photo-wizard .slick-slide > div {
  height: 100%;
}

.cover-photo-wizard .slick-list,
.cover-photo-wizard .slick-track,
.cover-photo-wizard .slick-slide {
  height: 100%;
  display: flex;
  flex-direction: var(--flex-item);
}

.cover-photo-wizard .slick-active {
  justify-content: center;
}

.cover-photo-wizard .slick-slider {
  flex: 1;
  height: calc(100% - 20rem);
  width: 100vw;
  direction: var(--direction-page);
}

@media only screen and (min-width: 768px) {
  .cover-photo-wizard .slick-slider {
    width: calc(100vw - 300px);
  }
}
