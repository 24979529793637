/*!*********************************/
/*        CALENDAR*/
/*        !**********************************/

.coupon-calendar .react-calendar {
  width: 600px;
}

.coupon-calendar .react-calendar__viewContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.coupon-calendar .react-calendar__navigation__arrow {
  object-fit: none;
  @apply object-none;
}

.coupon-calendar .react-calendar__navigation__label {
  font-size: 16px;
  /*@apply text-3xl;*/
}

.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  color: #c8c8c8;
}

.coupon-calendar .react-calendar__month-view__weekdays__weekday {
  font-size: 0.75rem;
  font-family: Poppins, serif;
  color: rgb(130 130 130);
  padding-bottom: 12px;
  text-align: center;
  text-decoration-line: none;
  /*@apply text-xs font-poppins text-custom-gray-3 pt-5 pb-3 text-center*/
}

.coupon-calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
  /*@apply hidden*/
}

.coupon-calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
  /*@apply hidden*/
}

.coupon-calendar .react-calendar__navigation {
  display: flex;
}

.coupon-calendar .react-calendar__navigation__label {
  display: flex;
  font-size: 1rem;
  justify-content: space-around;
  color: rgb(32 32 32);
}

.coupon-calendar .react-calendar__month-view {
  margin-left: 15px;
  margin-right: 15px;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

/*.react-calendar__navigation__label__divider {*/
/*    display: none;*/
/*}*/

.coupon-calendar .react-calendar__tile {
  width: 35px;
  height: 35px;
}

.coupon-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  font-size: 0.875rem;
  font-family: Poppins, serif;
}

.coupon-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration-line: none;
  text-decoration-thickness: 0;
}

.coupon-calendar .react-calendar__tile--active:enabled:hover,
.coupon-calendar .react-calendar__tile--active:enabled:focus {
  background-color: #2f80ed;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
  background-color: #2f80ed;
  color: #fff;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: #2f80ed;
  color: #fff;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  background-color: #2f80ed;
  color: #fff;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: #2f80ed;
  color: #fff;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: rgba(47, 128, 237, 0.2);
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
  background-color: rgba(47, 128, 237, 0.2);
}

.coupon-calendar .react-calendar__year-view__months {
  display: flex;
  flex-direction: column;
}

.coupon-calendar
  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  border-width: 1px;
  border-radius: 9999px;
  border-color: rgb(189 189 189);
}

.coupon-calendar
  .react-calendar__tile.react-calendar__year-view__months__month {
  width: 130px;
  border-color: black;
  border-width: 1px;
  padding-top: 5px;
}
